import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Terminal } from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";
import fetchData from "./../../../api/fetch"
const fitAddon = new FitAddon();
const OPTIONS_TERM = {
  useStyle: true,
  screenKeys: true,
  cursorBlink: true,
  cols: 200,
  theme: {
    background: "black",
  },
  screenReaderMode: true,
};
const term = new Terminal();
function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}
const socket = new WebSocket("ws://127.0.0.1:8001/ws");
const TerminalComponent = () => {
  let curr_line = ""
  let entries = []
  const terminalRef = useRef();
  useEffect(() => {
    socket.onmessage = (event) => {
 //     console.log(event.data);
     // term.write("\r\n")
      term.write(event.data)
      return () =>{
        //close web
        socket.close();
      }
    };
  },[])
  useEffect(() => {
    console.log("Socker state" + " " + socket.readyState);
    if (!terminalRef.current) {
      return
    }
   // term.loadAddon(fitAddon);
    term.open(terminalRef.current);
    term.write('$ ')
    // socket.onopen = () => {
    //   socket.send("Here's some text that the server is urgently awaiting!");
    // };
    //socket.send("Here's some text that the server is urgently awaiting!");
    let curr_line = ""
    let data = {}
    console.log("current  line" + " " + curr_line)  
    term.onData((bytes) => {
      console.log(bytes)
      data = {
        kind: 'input',
        message: bytes
      }
      term.write(bytes);
      socket.send(JSON.stringify(data))
   });
    return () => {};
  }, [terminalRef]);
  
  return (
    <div
      ref={terminalRef}
    ></div>
  );
};
export default function Project() {
  const [ podready, SetPodready] = useState(true)
  return (
    <>
      {podready ? 
      <TerminalComponent />
      : <div>Initialising pod</div>}
    </>
  );
}